import React, { useState } from "react";
import { ContainerTopBarDesk, ContainerTopBarMovil ,ContainerTopBarDeskPrincipal} from "./TopBarStyles";
// imagenes
import ArrowDown from "../../assets/images/menu/flechaabajo.svg";
import DefinicionIcon from "../../assets/images/menu/Topmenu/DefinicionestrategicaMovil.svg";
import FabricacionIcon from "../../assets/images/menu/Topmenu/fabricacionMovil.svg";
import IdeacicionIcon from "../../assets/images/menu/Topmenu/IdeacionyplandenegocioMovil.svg";
import DesarrolloIcon from "../../assets/images/menu/Topmenu/desarrolloMovil.svg";
import SeguimientoIcon from "../../assets/images/menu/Topmenu/SeguimientoMovil.svg";
const TopBar = ({
  ListData,
  windowSize,
  HanlderSelectRouter,
  ListDataMovil,
  RutaSeleccionada,
}) => {
  // onClick={() => setIniciativasList(sortedIniciativas)}
  const [ShowFloatMenuHome, setShowFloatMenuHome] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false); // Estado para controlar el submenú


  const HanlderShowFloatMenu = () => {
    if (ShowFloatMenuHome === true) {
      setShowFloatMenuHome(false);
    } else {
      setShowFloatMenuHome(true);
    }
  };
 
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu); // Alternar el submenú
  };


  return windowSize.width <= 837 ? (
    <ContainerTopBarMovil>
      <div
        onClick={() => HanlderShowFloatMenu()}
        className="ContainerArrowDownItem"
      >
        <div
          className="ItemMenuTopBarMovil"
          style={{ marginBottom: -10, paddingBottom: 0 }}
        >
          <p style={{ color: "#3F348B" }}>{RutaSeleccionada}</p>
        </div>
        <div>
          <img src={ArrowDown} alt="ArrowDown" />
        </div>
      </div>
      <div
        style={
          ShowFloatMenuHome === true
            ? { display: "block" }
            : { display: "none" }
        }
        className="FloatMenuMovilHome"
      >
        <div className="ItemMenuTopBarMovil">
          <p
            onClick={() => HanlderSelectRouter("TODOS",'')}
            style={{
              color: "#3F348B",
              paddingBottom: "0px !important",
              marginBottom: 5,
            }}
          >
            TODOS
          </p>
        </div>
        <div className="ItemMenuTopBarMovil">
        {ListDataMovil?.map((iniciativaMovil) => {
            return (
              <div key={iniciativaMovil?.id}>
                <p
                  onClick={() => {
                    if (iniciativaMovil?.nombre === "INICIATIVA INCREMENTAL") {
                      toggleSubMenu(); // Mostrar/ocultar el submenú
                    } else {
                      HanlderSelectRouter(
                        iniciativaMovil?.nombre,
                        iniciativaMovil?.color
                      );
                    }
                  }}
                  style={{ color: iniciativaMovil?.color }}
                >
                  {iniciativaMovil?.nombre}{" "}
                  {iniciativaMovil?.nombre === "INICIATIVA INCREMENTAL" && (
                    <span>{showSubMenu ? "▲" : "▼"}</span>
                  )}
                </p>

                {/* Submenú que solo aparece para "INICIATIVA INCREMENTAL" */}
                {showSubMenu &&
                  iniciativaMovil?.nombre === "INICIATIVA INCREMENTAL" && (
                    <div className="SubMenu">
                      <p
                        className="SubMenuItem"
                        onClick={() => HanlderSelectRouter(iniciativaMovil?.nombre, iniciativaMovil?.color)}
                      >
                        Extensión de fórmula
                      </p>
                      <p
                        className="SubMenuItem"
                        onClick={() => HanlderSelectRouter(iniciativaMovil?.nombre, iniciativaMovil?.color)}
                      >
                        Extensión de empaque
                      </p>
                      <p
                        className="SubMenuItem"
                        onClick={() => HanlderSelectRouter(iniciativaMovil?.nombre, iniciativaMovil?.color)}
                      >
                        Pilón
                      </p>
                    </div>
                  )}
              </div>
            );
          })}
        </div>
          <div className="ContainerImgMenumovil">
          <div onClick={() => HanlderSelectRouter("DEFINICIÓN ESTRATÉGICA",'')}>
          <img src={DefinicionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("IDEACIÓN Y PLAN DE NEGOCIO",'')}>
          <img src={IdeacicionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("DESARROLLO",'')}>
          <img src={DesarrolloIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("FABRICACIÓN",'')}>
          <img src={FabricacionIcon} alt="IConMenu" srcset="" />
        </div>
        <div onClick={() => HanlderSelectRouter("SEGUIMIENTO",'')}>
          <img src={SeguimientoIcon} alt="IConMenu" srcset="" />
        </div>
          </div>
      </div>
    </ContainerTopBarMovil>
  ) : (
    <ContainerTopBarDeskPrincipal>
          <nav className="menu">
      <ul>
        <li><a href="#" onClick={() => HanlderSelectRouter("TODOS", "")} className="TopBarTodos" >TODOS</a></li>
        {ListData.map((iniciativa, index) => (

          <li key={index} className={iniciativa?.nombre === "INICIATIVA INCREMENTAL" ? "submenu" : ""}>
            
            {/* Etiqueta del Menu */}
            <a href="#" className="TopBarItem"
               onClick={() => {
                if (iniciativa?.nombre === "INICIATIVA INCREMENTAL") {
                  toggleSubMenu(); // Mostrar/ocultar el submenú
                } else {
                  HanlderSelectRouter(
                    iniciativa?.nombre,
                    iniciativa?.color
                  );
                }
              }}

            style={
              RutaSeleccionada === iniciativa?.nombre
                ? { color: iniciativa?.color, opacity: 1 }
                : { color: iniciativa?.color, opacity: 0.3 }
            } >
              {iniciativa?.nombre}{" "}
                  {iniciativa?.nombre === "INICIATIVA INCREMENTAL" && (
                    <span>{showSubMenu ? "▲" : "▼"}</span>
                  )}

            </a>

            {iniciativa?.nombre === "INICIATIVA INCREMENTAL" &&(
              <ul className="submenu-items">
                  <li>
                    <a href="#" 
                     onClick={() => {
                      HanlderSelectRouter(iniciativa?.nombre, iniciativa?.color);
                      setShowSubMenu(false); // Cierra el submenú después de seleccionar una opción
                    }}
                    >Extensión de fórmula</a>
                  </li>
                  <li>
                    <a href="#" 
                     onClick={() => {
                      HanlderSelectRouter(iniciativa?.nombre, iniciativa?.color);
                      setShowSubMenu(false); // Cierra el submenú después de seleccionar una opción
                    }}
                    >Extensión de empaque</a>
                  </li>
                  <li>
                    <a href="#" 
                     onClick={() => {
                      HanlderSelectRouter(iniciativa?.nombre, iniciativa?.color);
                      setShowSubMenu(false); // Cierra el submenú después de seleccionar una opción
                    }}
                    >Pilón</a>
                  </li>
              </ul>
            )}

          </li>
        ))}
      </ul>
    </nav>
    </ContainerTopBarDeskPrincipal>
  );
};

export default TopBar;
