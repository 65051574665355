import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const ProyectoStyles = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 0%;
  .ContainerFlexProyectos {
    display: flex;
    margin-top: 2%;
    margin-left: 5%;
    text-align: initial;
  }
  
  .EtiquetaProyectos {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .InfoTextProyectos p {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 70px;
    line-height: 25px;
    color: #403f3f;
  }

  .ContainerNombreExito {
    color: #3F348B;
    font-size: 16px;
    font-family: "Montserrat-Bold", sans-serif;
    text-align: center;
    font-weight: bold;
  }
  .ButtonFase {
    position: relative;
    min-width: 40px;
    min-height: 17px;
    border-radius: 16px;
    background-clip: inherit;
    background-image: linear-gradient(
      rgb(63, 52, 139),
      rgb(74, 51, 140),
      rgb(84, 50, 141),
      rgb(94, 48, 141),
      rgb(103, 46, 141)
    );
    padding: 1px 2px;
    cursor: pointer;
    width: 25%;
    margin-top: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    color: #fff;
    font-family: "Montserrat-Bold", sans-serif;
    text-align: center;
    font-weight: bold;
  }
  .ButtonFase:hover{
      background: rgb(153, 38, 144);
    }
  .ContainerTitleproyect{
    text-align: initial;
    margin-left: 5%;
    width: 50%;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    color: #0E0233;
    font-size: 33px;
  }
  .LineProyect{
     width: 40%;
     background: red;
     height: 3px;
     margin-top: 10px;
     margin-bottom: 10px;
     background: transparent
      linear-gradient(
        105deg,
        #ffffff 0%,
        #b9db10 4%,
        #219348 10%,
        #00a99d 17%,
        #3565ac 60%,
        #3f348b 70%,
        #9b2690 85%,
        #be0c13 100%
      )
      0% 0% no-repeat padding-box;
  }

  // Mobil Frist
  @media screen and (min-width: 200px) {
    .ContainerFlexProyectos {
      flex-direction: column;
      margin-left: 10%;
  }
  .ButtonFase {
    width: 90%;
  }
   .ContainerProyectosLeft{
    flex-direction: column-reverse
   }
   .EtiquetaProyectos{
    width: 80%;
   }
   .ContainerProyectosLeft {
    width: 100%;
  }
  .ContainerTitleproyect{
    font-size: 20px;
  }
  .ContainerTitleproyect{
    margin-top: 20px;
    width: 80%;
   
  }
  .LineProyect{
     width: 70%;
    
  }
  .InfoTextProyectos p {
    font-size: 13px;
    margin-top: 25px;
    font-family: 'Montserrat-Medium', sans-serif;
    color:#403F3F;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .ContainerNombreExito p {
    font-weight: bold;
    font-size: 20px;
  }
  .slick-slide{
    border-radius: 10px !important;
  }

  }
  @media screen and (min-width: 720px) {
    .ContainerIconMenuProyectos p {

    font-size: 8px;
}
  }
  @media screen and (min-width: 900px) {
    .InfoTextProyectos p {
    font-size: 14px;
    margin-top: 30px;
    max-width: 80%;
   
  }
    .ContainerFlexProyectos {
      flex-direction: row;
      margin-left: 5%;
  }
  .ContainerProyectosLeft{
    flex-direction: column
   }
   .ContainerProyectosLeft {
    width: 60%;
  }
  .ButtonFase {
    width: 25%;
  }
  .ContainerTitleproyect{
 
 font-size: 25px;
}
.ContainerIconMenuProyectos p {

font-size: 12px;
}
.ContainerTitleproyect{
    
    width: 50%;
   
  }
  .LineProyect{
     width: 40%;
    
  }
  .ContainerNombreExito p {
    font-weight: bold;
    font-size: 24px;
  }
  }
  @media screen and (min-width: 1200px) {
    .ContainerTitleproyect{
 
    font-size: 33px;
  }
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }


   //***********************************
  // Estilos para submenu
  //***********************************

  /* Estilos para el contenedor del submenú */
  .SubMenu_tipo_proyectos {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: absolute;
    z-index: 100;
    /* Asegúrate de que se superponga correctamente */
    width: 200px;
    /* Ajusta el ancho si es necesario */
  }

   .SubMenu_tipo_proyectos_movil {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: absolute;
    z-index: 100;
    /* Asegúrate de que se superponga correctamente */
    width: 200px;
    left: 30%;
    /* Ajusta el ancho si es necesario */
  }

  /* Estilos para los elementos dentro del submenú */
  .SubMenuItem_tipo_proyectos {
    font-size: 11px;
    padding: 8px 12px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    color: #333;
    border-bottom: 3px solid #efefef;
  }

  /* Efecto hover en los elementos del submenú */
  .SubMenuItem_tipo_proyectos:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  /* Números de los elementos del submenú (estilo de numeración) */
  .SubMenuItem_tipo_proyectos::before {
    content: counter(item) ". ";
    /* Estilo de numeración automática */
    counter-increment: item;
    color: #7ab800;
    /* Ajusta el color del número si es necesario */
    font-weight: bold;
    margin-right: 8px;
  }

  /* Reinicia el contador de numeración */
  .SubMenu_tipo_proyectos {
    counter-reset: item;
  }

`;
